var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"update-credentials","title":"Update Credentials","cancel-title":"Logout","ok-title":"Submit","ok-variant":"primary","ok-disabled":_vm.isLoading,"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":""},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)},"show":_vm.onReset,"cancel":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""}},[_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('i',{staticClass:"fa fa-exclamation-circle"}),_vm._v("  You need to relogin after you update your credentials ")]),_c('b-form-group',{attrs:{"label":"Contact No.:","label-for":"contactNo","description":""}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
					required: true,
					regex: '^[+]639[0-9]{9}$',
				}),expression:"{\n\t\t\t\t\trequired: true,\n\t\t\t\t\tregex: '^[+]639[0-9]{9}$',\n\t\t\t\t}"}],staticClass:"numFont",attrs:{"name":"Contact No","type":"text","placeholder":"+639xxxxxxxxx"},model:{value:(_vm.form.contactNo),callback:function ($$v) {_vm.$set(_vm.form, "contactNo", $$v)},expression:"form.contactNo"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Contact No')),expression:"errors.has('Contact No')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s('Contact No. must follow this format: +639xxxxxxxxx')+" ")])],1),_c('b-form-group',{attrs:{"label":"Password:","label-for":"password"}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
						required: true,
						regex: _vm.passwordRegex,
					}),expression:"{\n\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\tregex: passwordRegex,\n\t\t\t\t\t}"}],attrs:{"name":"Password","placeholder":"Password","type":_vm.showPassword ? 'text' : 'password',"autocomplete":""},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.showPassword ? 'eye-slash' : 'eye'}})],1)])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Password')),expression:"errors.has('Password')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])],1)],1),_c('b-form-group',{attrs:{"label":"Confirm Password:","label-for":"confirmPassword","description":""}},[_c('b-input-group',[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
						required: true,
						regex: _vm.passwordRegex,
					}),expression:"{\n\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\tregex: passwordRegex,\n\t\t\t\t\t}"}],attrs:{"name":"Confirm Password","placeholder":"Confirm Password","type":_vm.showConfirmPassword ? 'text' : 'password',"autocomplete":"off"},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":function($event){_vm.showConfirmPassword = !_vm.showConfirmPassword}}},[_c('span',[_c('b-icon',{attrs:{"icon":_vm.showConfirmPassword ? 'eye-slash' : 'eye'}})],1)])],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Confirm Password')),expression:"errors.has('Confirm Password')"}],staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }